<!-- @format -->

<template>
	<transition name="modal-fade">
		<div>
			<div class="modal-backdrop">
				<div
					class="modal"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
				>
					<header class="modal-header" id="modalTitle">
						<slot name="header"></slot>
					</header>

					<section class="modal-body" id="modalDescription">
						<b-button
							type="button"
							class="is-danger"
							@click="onCloseEditForm"
							aria-label="Close modal"
						>
							X Close
						</b-button>
						<slot name="body"></slot>
						<div class="margin-top-10">
							<set-address-route />
						</div>
						<div>
							<b-button
								type="button"
								class="is-success"
								@click="onSaveEditForm"
							>
								Save the route
							</b-button>
						</div>
					</section>

					<footer class="modal-footer">
						<slot name="footer"></slot>
					</footer>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import SetAddressRoute from '@/_srcv2/views/pages/admin-reports/customer-route-list/SetAddressRoute.vue'
import useRouteListState from '@/_srcv2/views/pages/admin-reports/customer-route-list/useRouteListState.js'
import { onMounted } from '@vue/composition-api'
import SetRouteIdMutation from '@/_srcv2/views/pages/admin-reports/customer-route-list/SetRouteIdMutation.graphql'
import { useMutation } from '@vue/apollo-composable'

export default {
	name: 'ModalEditRoute',
	components: { SetAddressRoute },
	setup() {
		const {
			isModalVisible,
			selectedAddress,
			modelAddress,
			schemaAddress,
			formOptionsAddress,
			keyRouteTable,
		} = useRouteListState()
		const onCloseEditForm = () => (isModalVisible.value = false)
		const onSaveEditForm = () => {
			isModalVisible.value = false
			setRoute()
			keyRouteTable.value = keyRouteTable.value + 1
		}
		onMounted(() => console.log('isModalVisible', isModalVisible.value))
		// ---------------------------------------------------------------------------
		const {
			mutate: setRoute,
			onDone,
			onError,
		} = useMutation(SetRouteIdMutation, () => ({
			variables: {
				company_id: modelAddress.companyId,
				address_id: modelAddress.addressId,
				address_phone: modelAddress.phone,
				order_clerk: modelAddress.person,
				list_group: modelAddress.listGroup,
				open_hours: modelAddress.openHours,
			},
		}))
		onDone(() => window.alert('Route is set!'))
		onError((err) => alert('Error: ', err))
		return {
			onCloseEditForm,
			onSaveEditForm,
			isModalVisible,
			selectedAddress,
			modelAddress,
			schemaAddress,
			formOptionsAddress,
		}
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}

.modal {
	background: #ffffff;
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	max-width: 600px;
	max-height: 100%;
	height: 100%;
	margin: auto;
	padding: 20px;
	opacity: 1;
}

.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}

.modal-header {
	position: relative;
	color: #4aae9b;
	justify-content: space-between;
}

.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
}

.modal-body {
	position: relative;
	padding: 20px 10px;
}

.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
