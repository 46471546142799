<!-- @format -->

<template>
	<div>
		<div>
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-header">{{ tableTitleByType }}</div>
				<div>
					<b-dropdown :triggers="['hover']" aria-role="list">
						<template #trigger>
							<b-button
								label=""
								type="is-danger"
								class="width-250"
								icon-right="menu-down"
								style="margin-left: 20px; margin-bottom: 20px"
							>
								{{ buttonTag }}
							</b-button>
						</template>
						<b-dropdown-item aria-role="listitem" @click="callEveryWeek"
							>Call Every Week</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="callMonthly"
							>Call Monthly</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="visitMonthly"
							>Visit Monthly</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="customerCallsUs"
							>Customer Calls Us</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="getOthers"
							>Other Customers</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="getOnlyInvoice"
							>Only Invoice Address</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="getAll"
							>Get All</b-dropdown-item
						>
					</b-dropdown>
				</div>
			</div>

			<vue-good-table
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				@on-row-dblclick="onRowDoubleClick"
				:search-options="{
					enabled: true,
				}"
				:sort-options="sortOptions"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success">Select</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
			<div class="max-width-150">
				<modal-edit-route v-if="isModalVisible" @close="closeModal" />
			</div>
			<!-- <download-csv :data="rows">
				<div class="margin-bottom-30 margin-top-30">
					<b-button class="is-success"> Download the List </b-button>
				</div>
			</download-csv> -->
			<!--  -->
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					display: flex;
					justify-content: space-between;
				"
			>
				<div>
					<download-csv :data="mappedRows">
						<div class="margin-top-30 width-250" style="margin-left: 15px">
							<b-button type="is-danger" outlined>
								Download the Route List
							</b-button>
						</div>
					</download-csv>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, reactive, ref, computed } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import GetCustomerRouteListQuery from '@/_srcv2/views/pages/admin-reports/customer-route-list/GetCustomerRouteListQuery.gql'
import Store from '@/store'
import ModalEditRoute from '@/_srcv2/views/pages/admin-reports/customer-route-list/ModalEditRoute.vue'
import useRouteListState from '@/_srcv2/views/pages/admin-reports/customer-route-list/useRouteListState.js'

export default {
	name: 'CustomerRouteForm',
	components: { ModalEditRoute },
	setup() {
		const tableTitleByType = ref('Customer Route List (Filtered)')
		const {
			sortOptions,
			// ? -------------------
			isModalVisible,
			selectedAddress,
			addressId,
			companyId,
			ownerId,
			ownerTitle,
			ownerNickname,
			routeId,
			line,
			city,
			postCode,
			addressNickname,
			phone,
			person,
			listGroup,
			openHours,
		} = useRouteListState()
		const closeModal = () => (isModalVisible.value = false)
		const showModal = () => (isModalVisible.value = true)
		// ** -----------------------------------------------------------------------------------
		const queryVariables = ref({
			company_id: Store.getters.getUserCurrentCompany,
			list_group: 'Call Every Week',
		})
		const buttonTag = ref('')
		const callEveryWeek = () => {
			queryVariables.value = {
				company_id: Store.getters.getUserCurrentCompany,
				list_group: 'Call Every Week',
			}
			buttonTag.value = 'Call Every Week'
		}
		const callMonthly = () => {
			queryVariables.value = {
				company_id: Store.getters.getUserCurrentCompany,
				list_group: 'Call Monthly',
			}
			refetch()
			buttonTag.value = 'Call Monthly'
		}
		const visitMonthly = () => {
			queryVariables.value = {
				company_id: Store.getters.getUserCurrentCompany,
				list_group: 'Visit Monthly',
			}
			refetch()
			buttonTag.value = 'Visit Monthly'
		}
		const customerCallsUs = () => {
			queryVariables.value = {
				company_id: Store.getters.getUserCurrentCompany,
				list_group: 'Customer Calls Us',
			}
			buttonTag.value = 'Customer Calls Us'
		}
		const getOthers = () => {
			queryVariables.value = {
				company_id: Store.getters.getUserCurrentCompany,
				list_group: 'Other Customers',
			}
			buttonTag.value = 'Other Customers'
		}
		const getOnlyInvoice = () => {
			queryVariables.value = {
				company_id: Store.getters.getUserCurrentCompany,
				list_group: 'Only Invoice Address',
			}
			buttonTag.value = 'Only Invoice Address'
		}
		const getAll = () => {
			queryVariables.value = {
				company_id: Store.getters.getUserCurrentCompany,
			}
			buttonTag.value = 'Get All'
		}
		// ** -----------------------------------------------------------------------------------
		onMounted(() => {
			buttonTag.value = 'Call Every Week'
			refetch()
			// ? -------------------
			sortOptions.initialSortBy = [
				{ field: 'routeId', type: 'asc' },
				{ field: 'customer', type: 'asc' },
			]
		})
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, onResult } = useQuery(
			GetCustomerRouteListQuery,
			() => queryVariables.value,
			options,
		)
		onResult((result) => {
			fetchAddresses(result.data.addresses).then((data) => (rows.value = data))
		})
		const fetchAddresses = (data) => {
			return new Promise((resolve, reject) => {
				const addresses = data.map((item) => {
					return {
						customerId: item.customer.customer_id,
						customerTitle: item.customer.customer_title,
						customerNickname: item.customer.customer_nickname,
						customer: `${item.customer.customer_title} - ${item.customer.customer_nickname}`,
						line: `${item.line_1} ${item.line_2}`,
						city: item.city,
						postCode: item.post_code,
						routeId: item?._cities?.route_id_by_city || '',
						dayOfWeek: item?._cities?._adresses_route?.dayOfWeek || '',
						addressId: item.address_id,
						companyId: item.company_id,
						addressNickname: item.address_nickname,
						phone: item.address_phone || '',
						person: item.order_clerk || '',
						listGroup: item?.list_group || '',
						openHours: item?.open_hours || '',
					}
				})
				if (data !== null || undefined) {
					resolve(addresses)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const rows = ref([])
		const columns = [
			{
				label: 'Day of week',
				field: 'dayOfWeek',
				width: '100px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '200px',
			},
			{
				label: 'Street',
				field: 'line',
				width: '200px',
			},
			{
				label: 'City',
				field: 'city',
				width: '65px',
			},
			{
				label: 'Post Code',
				field: 'postCode',
				width: '85px',
			},
			{
				label: 'Route-Id',
				field: 'routeId',
				width: '85px',
			},
			{
				label: 'Phone',
				field: 'phone',
				width: '100px',
			},
			{
				label: 'Person',
				field: 'person',
				width: '100px',
			},
		]
		const onRowDoubleClick = (params) => {
			console.log(params.row)
			selectedAddress.value = params.row
			addressId.value = params.row.addressId
			companyId.value = params.row.companyId
			ownerId.value = params.row.customerId
			ownerTitle.value = params.row.customerTitle
			ownerNickname.value = params.row.customerNickname
			routeId.value = params.row.routeId
			line.value = params.row.line
			city.value = params.row.city
			postCode.value = params.row.postCode
			addressNickname.value = params.row.addressNickname
			phone.value = params.row.phone
			person.value = params.row.person
			listGroup.value = params.row.listGroup
			openHours.value = params.row.openHours
			showModal()
		}
		// ? ----------------------------------------------------------------
		const mappedRows = computed(() => {
			const mapped = rows.value.map((row) => {
				return {
					Customer: row.customer,
					Street: row.line,
					City: row.city,
					'Post Code': row.postCode,
					'Route-Id': row.routeId,
					Day: row.dayOfWeek,
					Phone: row.phone,
					'Contact Person': row.person,
					'List Group': row.listGroup,
					'Open Hours': row.openHours,
				}
			})
			return mapped
		})
		return {
			tableTitleByType,
			isModalVisible,
			showModal,
			closeModal,
			onRowDoubleClick,
			rows,
			columns,
			sortOptions,
			callEveryWeek,
			callMonthly,
			visitMonthly,
			customerCallsUs,
			getOthers,
			getAll,
			buttonTag,
			getOnlyInvoice,
			mappedRows,
		}
	},
}
</script>

<style scoped></style>
