import { render, staticRenderFns } from "./SetAddressRoute.vue?vue&type=template&id=5107f0f7&scoped=true&"
import script from "./SetAddressRoute.vue?vue&type=script&lang=js&"
export * from "./SetAddressRoute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5107f0f7",
  null
  
)

export default component.exports