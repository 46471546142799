<!-- @format -->

<template>
	<div>
		<layout-wide>
			<sub-navbar page="Utilities" />
			<div>
				<HeroBox :title="title" :subtitle="subtitle" :color="color" />
			</div>
			<div class="margin-top-20">
				<customer-route-form :key="keyRouteTable" />
			</div>
		</layout-wide>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'
import CustomerRouteForm from '@/_srcv2/views/pages/admin-reports/customer-route-list/CustomerRouteForm.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import useRouteListState from '@/_srcv2/views/pages/admin-reports/customer-route-list/useRouteListState.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'

export default {
	name: 'CustomerRouteList',
	components: { CustomerRouteForm, SubNavbar, HeroBox },
	setup() {
		const { keyRouteTable } = useRouteListState()
		// ? ------------------------------------------------------------------
		const title = ref('Customer Routes Page')
		const subtitle = ref('Get customer lists by route, edit rote list')
		const color = ref('is-info')
		return {
			keyRouteTable,
			title,
			subtitle,
			color,
		}
	},
}
</script>

<style scoped></style>
