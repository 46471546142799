var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticStyle:{"background-color":"rgb(76, 92, 122)"}},[_c('div',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.tableTitleByType))]),_c('div',[_c('b-dropdown',{attrs:{"triggers":['hover'],"aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{staticClass:"width-250",staticStyle:{"margin-left":"20px","margin-bottom":"20px"},attrs:{"label":"","type":"is-danger","icon-right":"menu-down"}},[_vm._v(" "+_vm._s(_vm.buttonTag)+" ")])]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.callEveryWeek}},[_vm._v("Call Every Week")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.callMonthly}},[_vm._v("Call Monthly")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.visitMonthly}},[_vm._v("Visit Monthly")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.customerCallsUs}},[_vm._v("Customer Calls Us")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.getOthers}},[_vm._v("Other Customers")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.getOnlyInvoice}},[_vm._v("Only Invoice Address")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.getAll}},[_vm._v("Get All")])],1)],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"search-options":{
				enabled: true,
			},"sort-options":_vm.sortOptions,"pagination-options":{
				enabled: true,
				mode: 'records',
				perPage: 10,
				position: 'bottom',
				perPageDropdown: [5, 10, 15, 20],
				dropdownAllowAll: true,
				setCurrentPage: 1,
				jumpFirstOrLast: true,
				firstLabel: 'First',
				lastLabel: 'Last',
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}},on:{"on-row-dblclick":_vm.onRowDoubleClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success"},[_vm._v("Select")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticClass:"max-width-150"},[(_vm.isModalVisible)?_c('modal-edit-route',{on:{"close":_vm.closeModal}}):_vm._e()],1),_c('div',{staticStyle:{"background-color":"rgb(76, 92, 122)","padding-bottom":"30px","display":"flex","justify-content":"space-between"}},[_c('div',[_c('download-csv',{attrs:{"data":_vm.mappedRows}},[_c('div',{staticClass:"margin-top-30 width-250",staticStyle:{"margin-left":"15px"}},[_c('b-button',{attrs:{"type":"is-danger","outlined":""}},[_vm._v(" Download the Route List ")])],1)])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }