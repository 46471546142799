<template>
	<div>
		<form id="set-route-id">
			<vue-form-generator
				@validated="onFormValidated"
				:schema="schemaAddress"
				:model="modelAddress"
				:options="formOptionsAddress"
			>
			</vue-form-generator>
		</form>
	</div>
</template>

<script>
import useRouteListState from '@/_srcv2/views/pages/admin-reports/customer-route-list/useRouteListState.js'

export default {
	name: 'SetAddressRoute',
	setup() {
		const { isModalVisible, modelAddress, schemaAddress, formOptionsAddress } =
			useRouteListState()
		const onFormValidated = () => 42
		return {
			isModalVisible,
			modelAddress,
			schemaAddress,
			formOptionsAddress,
			onFormValidated,
		}
	},
}
</script>

<style scoped></style>
