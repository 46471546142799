<!-- @format -->

<template>
	<div>
		<section class="hero margin-bottom-20" :class="props.color">
			<div class="hero-body">
				<p class="title">{{ props.title }}</p>
				<p class="subtitle">
					{{ props.subtitle }}
				</p>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
		},
		subtitle: {
			type: String,
		},
		color: {
			type: String,
		},
	},
	setup(props) {
		return { props }
	},
}
</script>

<style lang="scss" scoped></style>
